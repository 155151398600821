.underlined {
   display: inline-block;
   color: inherit;
   transition: background 0.15s cubic-bezier(0.33, 0.66, 0.66, 1) 0s;
   margin-top: 0.5em;
}

.u1{
   border-bottom: 0 solid #0BA5E9;
   box-shadow: 0 -12px 0 #0BA5E9 inset;
}

.u2{
   border-bottom: 0 solid #EC4899;
   box-shadow: 0 -12px 0 #EC4899 inset;
}

.u3{
   border-bottom: 0 solid #F87315;
   box-shadow: 0 -12px 0 #F87315 inset;
}