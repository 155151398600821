.image-wrap {
   overflow: hidden;
   margin: 50px auto;
}
.image-wrap img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   object-position: top center;
   transition: all 6s ease-in-out;
}
.image-wrap:hover img {
   object-position: bottom center;
}