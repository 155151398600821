.image-wrapped {
   overflow: hidden;
   // margin: 50px auto;
}
.image-wrapped img {
   width: 100%;
   height: 100%;
   object-fit: cover;
   object-position: top center;
   transition: all 6s ease-in-out;
}

// testing _______



// testing _______

.image-wrapped:hover img {
   object-position: bottom center;
}

.project-wrapper:hover{
   h5{
      transition: all 1s ease-in-out;
      display: none;
   }
      
   h6{
      display: block;
   }
}

